/**
 * Direttiva che costruisce l'elemento di una lista
 */

export interface IItemListDirectiveScope extends ng.IScope {
    goToItemDetail: Function;
	chartOptions: any;
	card: any;
	windowWidth: number;
	isWidthExceeded: boolean;
	itemType: any;
	hourValue: any;
	isItemOpen: boolean;
	isLearningPlan: boolean;
	isProject: boolean;
	isSection: boolean;
	isTechSkill: boolean;
	isSoftSkill: boolean;
	percentageItem: any;
	suggestedPerson: any;
	suggestedFromAdmin: any;
	hasSuggested: any;
	globalApplicationData: any;
	bgAcademyApplicationData: any;
	item: any;
	forceToggleIndicator: boolean;
	defaultOnSuggestion: boolean;
	iconToShow: string;
	iconToShowAlt: string;
	isItemAlreadyCertified: boolean;
	currentItemType: any;
	currentItem: any;
	isScorm: Function;
	isConsumed: boolean;
}
angular.module('app').directive("itemList", ($window, $state, BgacademyApplicationData, GlobalApplicationData, moment, $stateParams) => {
	return {
		restrict: 	'E',
		transclude : true,
		scope: {
			item: '=', // Oggetto interno contenente tutte le informazioni della riga che sto andando a costruire
            selectedItemList: "=", // bind sull'array che conterrà le righe selezionate
			forceToggleIndicator: '=', // Toggle indicatore di default (open, consumed, percentage...) / suggeritore
			page: '@',
			isLearningPlanEditingMode: '@',
			openItemDetail: '&',
			suggestedPersonExists: '&',
			toggleSuggestedPerson: '&',
			itemChildsExists: '&',
			toggleItemChilds: '&',
			currentItemType: '=',
			currentItem: '='
		},
		link: link,
		templateUrl: 'app/shared/itemList/itemList.html'
	};
	function link($scope: IItemListDirectiveScope, element: JQuery, attrs: ng.IAttributes){
		$scope.card = $scope.item.item ? $scope.item.item : $scope.item;

		// Verifica se come prima cosa devo mostrare la persona (non ho altri dati disponibili)
		$scope.defaultOnSuggestion = true;

		// Collegamento all'oggetto principale
		$scope.bgAcademyApplicationData = BgacademyApplicationData;
		
		// Configurazione della percentuale
		$scope.chartOptions = {
			animate: {
				duration: 800,
				enabled: true
			},
			barColor: '#1abc9c',
			scaleColor: '',
			trackColor: '#fff', 
			lineWidth: 2,
			lineCap: 'butt',
			size: 35
		}

		// Verifico se è un oggetto scorm
		$scope.isScorm = () => {
			if(($scope.item && $scope.item.itemType && $scope.item && $scope.item.itemType == BgacademyApplicationData.constants.SCORM_FREE ||  $scope.item && $scope.item.itemType && $scope.item && $scope.item.itemType == BgacademyApplicationData.constants.SCORM_INVITE) || ($scope.item && $scope.item.item && $scope.item.item.itemType && $scope.item.item.itemType == BgacademyApplicationData.constants.SCORM_FREE || $scope.item && $scope.item.item && $scope.item.item.itemType && $scope.item.item.itemType == BgacademyApplicationData.constants.SCORM_INVITE)){
				return true;
			} else {
				return false;
			}
		}

		// Tipo dell'oggetto (video, audio, e-book...)
		$scope.itemType = null;

		// Valore formativo
		$scope.hourValue = null;

		// Oggetto aperto
		$scope.isItemOpen = false;

		// Verifica se è una competenza tecnica
		$scope.isTechSkill = false;

		// Verifica se è una competenza soft
		$scope.isSoftSkill = false;

		// Verifica se l'item è stato completato
		$scope.isConsumed = false;

		// Persona che ha suggerito l'elemento
		$scope.suggestedPerson = null;

		// Suggerito dall'amminsitrazione
		$scope.suggestedFromAdmin = false;

		// Verifica se è un learning Plan
		$scope.isLearningPlan = false;

		// Verifica se è un progetto
		$scope.isProject = false;

		// Verifica se è una sezione
		$scope.isSection = false;

		// Verifica se ha suggeritori
		$scope.hasSuggested = false;

		// Collegamento all'oggetto globale
		$scope.globalApplicationData = GlobalApplicationData;

		$scope.windowWidth = $window.innerWidth;
		// Cambio la classe del titolo in base alla sua lunghezza, così diminuendo il font-size evito che sfori
		if($scope.card && $scope.card.title && $scope.card.title.length < 62){
			$scope.card.titleClass = 'card-title-h3';
		} else if($scope.card && $scope.card.title && $scope.card.title.length > 62 && $scope.card.title.length > 62) {
			$scope.card.titleClass = 'card-title-h2';
		}

		// Eseguo la verifica sul carattere anche al resize e al caricamento della pagina
		angular.element($window).bind('resize', () => {
			// Calcolo la larghezza della finestra
			$scope.windowWidth = $window.innerWidth;

			if($scope.windowWidth < 600 && $scope.card && $scope.card.title && $scope.card.title.length < 62){
				$scope.card.titleClass = 'card-title-h6';
			} else if($scope.isWidthExceeded == true){
				$scope.card.titleClass = 'card-title-h3';
			}

			if($scope.windowWidth < 600 && $scope.card && $scope.card.title && $scope.card.title.length > 62 && $scope.card.title.length > 62){
				$scope.card.titleClass = 'card-title-h5';
			} else if($scope.isWidthExceeded == true){
				$scope.card.titleClass = 'card-title-h2';
			}
		});
		// e devo controllare al caricamento della pagina
		angular.element(document).ready(() => {
			// Calcolo la larghezza della finestra
			$scope.windowWidth = $window.innerWidth;

			if($scope.windowWidth < 600 && $scope.card && $scope.card.title && $scope.card.title.length < 62){
				$scope.card.titleClass = 'card-title-h6';
			} else if($scope.isWidthExceeded == true){
				$scope.card.titleClass = 'card-title-h3';
			}

			if($scope.windowWidth < 600 && $scope.card && $scope.card.title && $scope.card.title.length > 62 && $scope.card.title.length > 62){
				$scope.card.titleClass = 'card-title-h5';
			} else if($scope.isWidthExceeded == true){
				$scope.card.titleClass = 'card-title-h2';
			}
		});

		// Verifico gli attributi dell'oggetto
		if($scope.card) {
			if($scope.card.itemAttributes){
				let itemAttributes = $scope.card.itemAttributes;
				for(let k = 0; k < itemAttributes.length; k++){
					// Verifico se è una competenza soft
					if(itemAttributes[k].attributeType == BgacademyApplicationData.constants.SOFT_SKILL){
						$scope.isSoftSkill = true;
					}

					// Verifico se è una competenza tecnica
					if(itemAttributes[k].attributeType == BgacademyApplicationData.constants.TECHNICAL_SKILL){
						$scope.isTechSkill = true;
					}

					// Verifico il tipo di oggetto
					if(itemAttributes[k].attributeType == BgacademyApplicationData.constants.OBJECT_TYPE){
						$scope.itemType = itemAttributes[k].attributeValue;
						if($scope.itemType == BgacademyApplicationData.constants.LEARNING_PLAN){
							$scope.isLearningPlan = true;
						} else if(itemAttributes[k].attributeValue == BgacademyApplicationData.constants.PROJECT){
							$scope.isProject = true;
						} else if(itemAttributes[k].attributeValue == BgacademyApplicationData.constants.SECTION){
							$scope.isSection = true;
						}
					}

					// Verifico se c'è il valore formativo
					if(itemAttributes[k].attributeType == BgacademyApplicationData.constants.VALUE){
						let duration = moment.duration(parseInt(itemAttributes[k].attributeValue), 'seconds');
						$scope.hourValue = {
							hours: duration.minutes(),
							minutes: duration.seconds()
						}
					}
				}
			}

			// Se possiede la scormRegistration, prendo da qui la percentuale di avanzamento
			let hasScormRegistration: boolean = $scope.item && $scope.item.scormRegistration ? true : false;
			if (hasScormRegistration) {
				$scope.percentageItem = $scope.item.scormRegistration.score;
			}

			// Se possiede l'itemRegistration (quindi non è uno scorm ma è un oggetto Kaltura), prendo da qui la percentuale di avanzamento
			let hasItemRegistration: boolean = $scope.card && $scope.card.itemRegistration ? true : false;
			if (hasItemRegistration) {
				$scope.percentageItem = $scope.card.itemRegistration.currentPrecentage;
			}

			// Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente
			if($scope.card.engagements){
				for(let d = 0; d < $scope.card.engagements.length; d++){
					// Verifico se l'item è stato concluso 
					if($scope.card.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_CONSUMED){
						$scope.isConsumed = true;
					}
					
					//let found = false;

					if($scope.card.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.card.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_OPEN){
						// Oggetto aperto
						$scope.isItemOpen = true;
					}
					

					if($scope.card.engagements[d].engagementDetails && $scope.card.engagements[d].engagementDetails.length &&
						$scope.card.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && (
						$scope.card.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_CONSUMED || 
						$scope.card.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_STARTED)){
						// Recupero la percentuale
						for(let z = 0; z < $scope.card.engagements[d].engagementDetails.length; z++){
							if(!hasItemRegistration && !hasScormRegistration && $scope.card.engagements[d].engagementDetails[z].detailKey == 'PERCENTAGE'){
								$scope.percentageItem = $scope.card.engagements[d].engagementDetails[z].detailValue;
								//found = true;
								break;
							}
						}
					}
					else if($scope.card.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.card.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_OPEN){
						// Oggetto aperto
						$scope.isItemOpen = true;
					}
					//if (found) {
					//	break;
					//}
					
					// Verifico la certificazione
					if($scope.card.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.card.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_CERTIFIED){
						// L'oggetto è già stato certificato
						$scope.isItemAlreadyCertified = true;
					}
				}

				// Se l'item è consumed, mostro la percentuale al 100%
				if($scope.isConsumed && !hasScormRegistration && !hasItemRegistration){
					$scope.percentageItem = "100";
				}
			}

			// TODO-Allloy cerco anche fra i dati che ora mi mancano (certificazione, etc)
			if ($scope.isItemOpen || $scope.percentageItem || $scope.isItemAlreadyCertified) {
				$scope.defaultOnSuggestion = false;
			}

			if($scope.card.itemVisibilities){
				// Ordino le visibilities per data
				$scope.card.itemVisibilities.sort((a: any, b: any) => {
					if (a.creationDate > b.creationDate) {
						return -1;
					} else if (a.creationDate < b.creationDate) {
						return 1;
					} else {
						return 0;
					}
				});

				// Verifico se ci sono suggeriti (visibility = 'suggestion'), e se ci sono li aggiungo alla lista
				for(let j = 0; j < $scope.card.itemVisibilities.length; j++){
					// Se è stato suggerito dall'amministrazione, ha la precedenza
					if($scope.card.itemVisibilities[j].visibilityType == BgacademyApplicationData.constants.SUGGESTION && $scope.card.itemVisibilities[j].fromAdmin == true){
						$scope.suggestedFromAdmin = true;
						$scope.hasSuggested = true;
						break;
					} else if($scope.card.itemVisibilities[j].visibilityType == BgacademyApplicationData.constants.SUGGESTION){
						// Verifico se ho i permessi per visualizzarlo
						let groupFound: boolean = false;
						for(let z = 0; z < $scope.globalApplicationData.userGroups.length; z++) {
							if ($scope.globalApplicationData.userGroups[z].groupId == $scope.card.itemVisibilities[j].allowedGroupId) {
								groupFound = true;
								break;
							}
						}
						if(groupFound || ($scope.globalApplicationData.jwtPayload.user.userId == $scope.card.itemVisibilities[j].allowedUserId)){
							$scope.hasSuggested = true;

							// Salvo chi me l'ha suggerito
							$scope.suggestedPerson = $scope.card.itemVisibilities[j].userObject;
							break;
						}
					}
				}
			}
		}


		// Controllo quale icona mostrare
		$scope.iconToShow = "EMPTY";
		$scope.iconToShowAlt = "EMPTY";
		// Versione standard
		if ($scope.card.objDisable) {
			$scope.iconToShow = "DISABLED";
		}
		else if ($scope.isItemAlreadyCertified) {
			$scope.iconToShow = "CERTIFICATE";
		}
		else if ($scope.percentageItem) {
			$scope.iconToShow = "PERCENTAGE";
		}
		else if ($scope.isItemOpen) {
			$scope.iconToShow = "OPEN";
		}
		/*
		else if (scope.suggestedFromLibrary) {
			$scope.iconToShow = "LIBRARY";
		}
		*/
		else if ($scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
		}
		else if ($scope.hasSuggested) {
			$scope.iconToShowAlt = "PERSON";
		}
		// Versione con forza persona
		if ($scope.card.objDisable) {
			$scope.iconToShowAlt = "DISABLED";
		}
		else if ($scope.forceToggleIndicator && $scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
		}
		else if ($scope.forceToggleIndicator && $scope.hasSuggested) {
			$scope.iconToShowAlt = "PERSON";
		}
		else if ($scope.isItemAlreadyCertified) {
			if ($scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
			}
			else if ($scope.hasSuggested){
			$scope.iconToShowAlt = "PERSON";
			}
			else {
				$scope.iconToShowAlt = "CERTIFICATE";
			}
		}
		else if ($scope.percentageItem) {
			if ($scope.suggestedFromAdmin) {
				$scope.iconToShowAlt = "ADMIN";
			}
			else if ($scope.hasSuggested) {
				$scope.iconToShowAlt = "PERSON";
			}
			else {
				$scope.iconToShowAlt = "PERCENTAGE";
			}
		}
		else if ($scope.isItemOpen) {
			if ($scope.suggestedFromAdmin) {
				$scope.iconToShowAlt = "ADMIN";
			}
			else if ($scope.hasSuggested) {
				$scope.iconToShowAlt = "PERSON";
			}
			else {
				$scope.iconToShowAlt = "OPEN";
			}
		}
		/*
		else if (scope.suggestedFromLibrary) {
			if ($scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
			}
			else if ($scope.hasSuggested){
			$scope.iconToShowAlt = "PERSON";
			}
			else {
			$scope.iconToShowAlt = "LIBRARY";
			}
		}
		*/
		else if ($scope.suggestedFromAdmin) {
			$scope.iconToShowAlt = "ADMIN";
		}
		else if ($scope.hasSuggested) {
			$scope.iconToShowAlt = "PERSON";
		}
		
		// Porta alla pagina di dettaglio dell'oggetto selezionato
		$scope.goToItemDetail = (itemId: string) => {
			// Se l'id passato alla funzione è diverso dall'id dell'oggetto Item significa che sto aprendo
			// la card di un elemento suggerito all'interno di un Item (es Learning Plan)
			if(itemId != $scope.card.itemId){ 
				if($scope.itemType == BgacademyApplicationData.constants.LEARNING_PLAN) {
					$state.go("app.bgacademyApp.itemDetail", { itemId: $scope.item.itemId} );
				} else if($scope.currentItemType == BgacademyApplicationData.constants.SECTION) {
					$state.go("app.bgacademyApp.itemDetailSec", { projectId: $stateParams.projectId, sectionId: $stateParams.itemId, itemId: $scope.item.itemId} );
				} else if($scope.currentItemType == BgacademyApplicationData.constants.LEARNING_PLAN) {
					// Sto accedendo a un oggetto contenuto in un learning plan
					$state.go("app.bgacademyApp.itemDetailLp", { lpId: $stateParams.itemId, itemId: $scope.item.itemId} );
				} else {
					$state.go("app.bgacademyApp.itemDetail", { itemId: $scope.card.itemId });
				}

			} else {
				// Se è un progetto, vado nel dettaglio del progetto
				if($scope.itemType == BgacademyApplicationData.constants.PROJECT){
					$state.go("app.bgacademyApp.projectDetail", { itemId: itemId, breadcrumbs: null });
				} else if($scope.currentItemType == BgacademyApplicationData.constants.LEARNING_PLAN) {
					$state.go("app.bgacademyApp.itemDetail", { itemId: itemId } );
				} else {
					$state.go("app.bgacademyApp.itemDetail", { itemId: itemId} );
				}
			}
        }
	}
});